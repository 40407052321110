import stableStringify from "fast-json-stable-stringify";
import {
  gql,
  ApolloProvider,
  useLazyQuery,
  useQuery,
  useMutation,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  from
} from "@apollo/client";

import { auth } from "./firebase";

let { authorization } = typeof window === "undefined" ? {} : localStorage;

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization:
        typeof window === "undefined"
          ? window.AUTHORIZATION || authorization
          : authorization // jwt override for testing
    }
  }));

  return forward(operation);
});

const apolloClient = new ApolloClient({
  connectToDevTools: false,
  cache: new InMemoryCache(),
  link: from([
    authMiddleware,
    createHttpLink({ uri: `${process.env.NEXT_PUBLIC_API_SERVER}/graphql` })
  ]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    query: {
      errorPolicy: "ignore",
      fetchPolicy: "network-only"
    }
  }
});
export default apolloClient;
export { gql, useLazyQuery, useQuery, useMutation, ApolloProvider };
export const convertToID = obj => btoa(stableStringify(obj));
export const fetchFromApiServerRaw = ({ path, body, json = true, ...rest }) =>
  fetch(`${process.env.NEXT_PUBLIC_API_SERVER}/${path}`, {
    method: body ? "post" : "get",
    headers: {
      authorization,
      "Content-Type": json ? "application/json" : undefined
    },
    credentials: "include", // include cookies in requests sent to the api server
    body: body ? (json ? JSON.stringify(body) : body) : undefined,
    ...rest
  });
export async function fetchFromApiServer({ path, body, json = true, ...rest }) {
  try {
    const response = await fetchFromApiServerRaw({
      path,
      body,
      json,
      ...rest
    });
    const results = await response.json();

    return results;
  } catch (error) {
    console.error(error);
  }
}

export function subscribe(queryOptions, callback) {
  const subscription = apolloClient
    .watchQuery(queryOptions)
    .subscribe({ next: callback });

  return () => subscription.unsubscribe();
}
export function watchQuery(options, callback, error) {
  // 1 time http request, using cache-and-network policy
  const subscription = apolloClient.watchQuery(options).subscribe({
    next(results) {
      callback(results);

      subscription.unsubscribe();
    },
    error
  });
}
export async function graphQuery(options) {
  try {
    const { data } = await apolloClient.query(options);

    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
export async function fetcher(url) {
  const res = await fetch(url);

  return res.json();
}
export async function fetchText(url) {
  const res = await fetch(url);

  return res.text();
}
export const fetcherAPIServer = path => fetchFromApiServer({ path });
export const fetcherAPIServerRaw = path => fetchFromApiServerRaw({ path });
/** This function MUST receive an object that contains new references to all sub objects,
 * it passes to the service worker, and in doing so, no refs passed to it can be used within
 * the browser app's scripts.
 */
export function postMessageToServiceWorker(type, message = {}) {
  try {
    navigator.serviceWorker?.ready?.then(registration =>
      registration.active.postMessage({
        applicationMessage: true,
        type,
        message
      })
    );
  } catch (error) {
    console.error(error);
  }
}

if (typeof window !== "undefined") {
  // keep session cookie updated
  auth.Auth.onIdTokenChanged(async user => {
    if (user) {
      try {
        var jwt = await user.getIdToken();
      } catch (error) {
        console.error(error);
      }
    }
    // set in storage to allow JWT to be used in page reloads
    if (jwt) {
      const { hostname } = new URL(process.env.NEXT_PUBLIC_API_SERVER);
      const domain = hostname.substring(hostname.indexOf(".") + 1);

      authorization = `Bearer ${jwt}`;
      localStorage.authorization = authorization;
      document.cookie = `jwt=${jwt}; domain=${domain}; path=/; SameSite=None; Secure;`;

      // send the jwt to the service worker when it changes
      // strings are pass by value
      postMessageToServiceWorker("auth", jwt);
    } else {
      authorization = undefined;
      localStorage.removeItem("authorization");
    }
  });
}
